<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Holiday Group Details</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/holiday-groups/">Holiday Groups</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Holidays</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <br />
            <div >
                <h4>Holiday Group</h4>
                <b-card>
                    <b-card-text>
                        <span class="font-weight-bold"> Name</span>
                        :
                        <span class="text-capitalize">{{this.holiday_group}}</span>
                    </b-card-text>
                </b-card>
                <br /><br />
                <div class="pb-2">
                    <h4>Holidays</h4>
                </div>
            </div>
            <b-row>
                <b-col lg="3" class="mb-0.5rem">
                    <router-link
                        v-if="hasPermission('CAN_MANAGE_HOLIDAYS')"
                        :to="{name: 'create-holiday', params: { holiday_group: this.urlBody.holiday_group }}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Holiday
                    </router-link>
                </b-col>

                <b-col lg="2" sm="12" class="mb-0.5rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-2 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Year</label>
                        <div class="col-lg-10 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="urlBody.year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>

                <b-col lg="1">
                    <button
                        type="button"
                        class="btn btn-primary text-right "
                        v-on:click="onSelection()"
                    >Filter</button>
                </b-col>
                
                <b-col lg="6">
                </b-col>   
                <!-- <b-col lg="6" class="mb-0.5rem">
                    <b-form-group
                        label="Search"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-weight:bold; font-size:1rem !important;"
                    >
                        <b-input-group size="md">
                            <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type Holiday to Search"
                                v-on:keyup.enter="onFiltered"
                                @search="clearSearch"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="onFiltered"
                                    class="btn btn-primary"
                                >Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col> -->
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >
                <template v-slot:cell(actions)="row">
                    <router-link
                        :to="{ name: 'edit-holiday', params: {holiday_group: row.item.holiday_group_id ,id: row.item.id} }"
                    >
                        <font-awesome-icon
                            v-if="hasPermission('CAN_MANAGE_HOLIDAYS')"
                            icon="pen"
                            class="mr-3"
                        ></font-awesome-icon>
                    </router-link>&nbsp; &nbsp;
                    <font-awesome-icon
                        v-if="hasPermission('CAN_MANAGE_HOLIDAYS')"
                        icon="trash-alt"
                        @click="info(row.item, row.index, $event.target)"
                        class="mr-3 text-danger"
                        style="cursor:pointer"
                        v-b-modal.modal-center
                    ></font-awesome-icon>
                </template>
                <template v-slot:cell(name)="row">
                     {{str_title(row.item.name)}}
                </template>
            </b-table>
            
             <!-- Info modal -->
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="sm"
                @hide="resetInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancel()">Cancel</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="deleteHoliday(infoModal.content)"
                        style="margin-right:10px;"
                    >Delete</b-button>
                </div>
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "date",
                order:"asc",
                holiday_group:"",
                year: new Date().getFullYear(),
            },
            api_error: "",
            sortBy: "id",
            sortDesc: false,
            holiday_groups: [
                { value: "", text: "All" }
            ],
            fields: [
                { key: "date", sortable:true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { key: "name", sortable:true,
                    formatter: value => {
                        return value != null
                            ? value.charAt(0).toUpperCase() + value.slice(1)
                            : "";
                    }
                },
                { key: "actions", label: "Actions"}
            ],
            years: [],
            items: null,
            filter: null,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
            total_years:2,
            holiday_group: "",
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_HOLIDAYS");
        this.urlBody.holiday_group = this.$route.params.holiday_group
        this.onPageLoad();
        this.getYears();
        if (this.hasPermission("CAN_MANAGE_HOLIDAYS")) {
            this.getHolidayGroups();
        }
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getYears() {
            const currentYear = (new Date()).getFullYear();
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
        },

        onPageLoad() {
            let api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            api_params = this.deleteEmptyKeys(this.$route.query);
            this.urlBody.sort_by = api_params.sort_by
                ? api_params.sort_by
                : this.urlBody.sort_by;
            this.urlBody.order = api_params.order
                ? api_params.order
                : this.urlBody.order;
            this.urlBody.holiday_group = api_params.holiday_group
                ? api_params.holiday_group
                : this.urlBody.holiday_group;
            this.urlBody.year = api_params.year
                ? api_params.year
                : this.urlBody.year;

            this.getHolidays(this.urlBody);
        },

        getDefaultFilters() {
            let api_params = {
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
                holiday_group: this.urlBody.holiday_group,
                year: this.urlBody.year
            };
            return api_params;
        },

        getHolidayGroups() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id: this.urlBody.holiday_group
            };
            this.axios.get(this.$api.get_holiday_groups, query).then(response => {
                this.holiday_group = response.data.data.name;
                this.updateProgressBar(true);
            })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete <b>'+item.name+'</b>?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },

        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },

        onSelection() {
            var query =this.deleteEmptyKeys(this.urlBody);
            this.getHolidays(query);
        },

        onFiltered() {
            if (this.filter) {
                let urlBody = this.deleteEmptyKeys(this.$route.query);
                urlBody.query = this.filter;
                this.getHolidays(urlBody);
            }
         
        },

        getHolidays: function(params) {
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios
                .get(this.$api.get_holidays, query)
                .then(response => {
                    let result = response.data.data;
                    this.items = result;
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(params)
                        }).catch(()=>{})
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        deleteHoliday: function(holiday) {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            this.axios
                .post(
                    this.$api.delete_holiday,
                    JSON.parse(holiday),
                    query
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.infoModal.id);
                        this.success = response.data.message;
                        this.getHolidays(this.getDefaultFilters());
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },

        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            this.api_error = "";
            this.getHolidays(urlBody)
        },

        clearSearch (event) {
            if (event.target.value === "") {
                if (!this.filter) {
                    this.api_error = "";
                    this.getHolidays(this.getDefaultFilters());
                }
            }
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>